/* src/assets/HomePage.css */
.home {
  text-align: center;
  color: white;
  background-color: #187614; /* Ensure the background color is consistent */
  padding: 50px 20px;
}

.hero-image {
  max-width: 20%;  /* Adjust this percentage to change the size of the image */
  height: auto;
  margin-bottom: 20px;

}

.title {
  font-size: 3em;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 1.5em;
  margin-bottom: 30px;
}

.description {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.levels {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.level {
  background-color: #ffffff20; /* Slightly transparent white background */
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.level a {
  color: white;
  text-decoration: none;
  font-size: 1.2em;
}

.level:hover {
  background-color: #ffffff40; /* Slightly more transparent on hover */
}
.socials {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.socials a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.socials a:hover {
  color: #ffcc00;
}

.contract-address {
  margin-top: 20px;
  color: white;
  font-size: 1.2rem;
}