/* src/components/Leaderboard.css */
.leaderboard {
  text-align: center;
  margin-top: 50px;
}

.leaderboard h1 {
  font-size: 32px;
  margin-bottom: 20px;
}

.leaderboard table {
  margin: 0 auto;
  border-collapse: collapse;
  width: 80%;
}

.leaderboard th, .leaderboard td {
  border: 1px solid #ddd;
  padding: 8px;
}

.leaderboard th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.leaderboard tr:nth-child(even) {
  background-color: #f9f9f9;
}

.leaderboard tr:hover {
  background-color: #ddd;
}
