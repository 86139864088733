/* src/App.css */
body, html {
  background-color: #187614;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.home, .game-level-info, .game {
  color: #fff;
}
